

















































import Vue from 'vue';
import { required, minLength, sameAs } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import ValidatedInput from '@/components/forms/ValidatedInput.vue';
import PasswordComplexityValidator from '@/services/validators/PasswordComplexityValidator';
import ToastMessage from '@/models/ToastMessage';
import AuthService from '@/services/AuthService';
import { MetaInfo } from 'vue-meta';

const validations = {
  user: {
    password: {
      minLength: minLength(12),
      required,
      PasswordComplexityValidator,
    },
    passwordConfirmation: {
      minLength: minLength(12),
      sameAsPassword: sameAs('password'),
      required,
    },
  },
};

export default Vue.extend({
  mixins: [validationMixin],
  validations: validations,
  components: { ValidatedInput },
  data() {
    return {
      user: {
        password: '',
        passwordConfirmation: '',
      },
      loading: false,
      message: '',
    };
  },
  props: {
    token: {
      type: String,
    },
    userId: {
      type: String,
    },
  },
  metaInfo(): MetaInfo {
    return { title: this.$t('pages.auth.ResetPassword.meta.title').toString() };
  },
  mounted() {
    this.setBreadCrumb();
  },
  computed: {
    invalid(): boolean {
      return this.$v.$invalid;
    },
  },
  methods: {
    checkErrors(errors: Array<string>) {
      return errors.length == 0;
    },

    setBreadCrumb() {
      this.$store.commit('UiStoreModule/setBreadcrumbItems', [
        { translationKey: 'pages.auth.ResetPassword.breadcrumb.last' },
      ]);
    },

    handle() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.message = this.$t('forms.common.error_message').toString();
        this.$store.commit(
          'addToastMessage',
          new ToastMessage(this.$t('forms.common.error_message').toString(), 'bg-warning')
        );
      } else {
        this.loading = true;
        this.message = '';
        return AuthService.resetPassword({
          password: this.user.password,
          passwordConfirmation: this.user.passwordConfirmation,
          userId: this.userId,
          token: this.token,
        }).then(
          () => {
            this.$router.push({ name: 'Login' });
            this.$store.commit('addToastMessage', new ToastMessage('Erfolgreich', 'bg-success'));
            this.$router.push({ name: 'Login' });
          },
          (error) => {
            this.loading = false;
            this.message = error;
            this.$store.commit('addToastMessage', new ToastMessage('Fehlgeschlagen', 'bg-warning'));
          }
        );
      }
    },
  },
});
